<template>
  <v-app style="font-family: Kanit,serif" :style="{background: $vuetify.theme.themes.light.background}">
    <v-main style="display: flex;flex-flow: column;height: 100px">
      <router-view/>
    </v-main>
    <!--    <Loading v-if="$store.state.isLoading"/>-->
    <v-snackbar
        :dark="false"
        v-model="$store.state.snackbar.visible"
        timeout="2000"
        top
        centered
        :color="$store.state.snackbar.color"
    >
      {{ $store.state.snackbar.text }}
    </v-snackbar>
    <Loading v-if="$store.state.isLoading"/>
  </v-app>
</template>

<script>
import axios from 'axios'
import Loading from "@/components/Loading";

export default {
  components: {Loading},
  name: 'App',
  data: () => ({
    //
  }),
  async created() {
    let res = await axios.get('/api/accounts/user/')
    await this.$store.commit("setAuthUser", {
      authUser: res.data.data,
      isAuthenticated: true,
    },)
  }
};
</script>

<style lang="scss">
.v-main__wrap {
  height: 100%;
}

.m-auto {
  transform: translate(-50%, -50%) !important;
  position: absolute !important;
  left: 50%;
  top: 50%;
  width: 100%;
}

.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.v-textarea .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.v-textarea.v-text-field--enclosed.v-text-field--outlined:not(.v-input--dense) textarea {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.main-container {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column;
}

.v-tooltip__content {
  /*font-size: 50px !important;*/
  opacity: 1 !important;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /*display: block !important;*/
}

/*.v-text-field__details{*/
/*  !*background-color: #1b6d85;*!*/
/*  position: absolute;*/
/*  top: 32px;*/
/*  left: -12px;*/
/*  text-align: left;*/
/*  padding-left: -12px !important;*/
/*}*/
/*main{*/
/*  padding-top: 0 !important;*/
/*}*/
.font-size-5 {
  font-size: 1.125rem !important;
}

.font-bold {
  font-weight: bold;
}
</style>
