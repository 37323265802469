export default [
    // {
    //     path: '/',
    //     name: 'Layout',
    //     component: () => import('@/components/Layout'),
    //     children: [
    //         {
    //             path: '/',
    //             name: 'dashboard',
    //             component: () => import('../views/Dashboard')
    //         },
    //         {
    //             // name:'create-order',
    //             path: '/order/create',
    //             component: () => import('../views/Order/CreateOrder'),
    //             meta: {
    //                 title: 'เปิดออเดอร์',
    //             }
    //         },
    //         {
    //             path: '/order',
    //             component: () => import('../views/Order/OrderList')
    //         }
    //     ]
    // },
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: () => import('../components/Login.vue')
    // },
    {
        path: '/',
        component: () => import('@/components/Layout'),
        meta: {breadCrumb: 'หน้าหลัก', icon: 'mdi-view-dashboard'},
        children: [
            {path: '/', component: () => import('@/views/Home')}
        ]
    },
    {
        path: '/dashboard',
        component: () => import('@/components/Layout'),
        meta: {breadCrumb: 'Dashboard', icon: 'mdi-view-dashboard'},
        children: [
            {path: '/dashboard', component: () => import('@/views/Dashboard')}
        ]
    },
    {
        path: '/order',
        component: () => import('@/components/Layout'),
        meta: {breadCrumb: 'คำสั่งซื้อ', icon: 'mdi-view-list'},
        children: [
            {
                path: '/order',
                component: () => import('@/views/order/OrderList'),
                meta: {permission: 'view_order'},
            },
            {
                path: '/order/create',
                component: () => import('@/views/order/CreateOrder'),
                meta: {permission: 'add_order', breadCrumb: 'เปิดออเดอร์ใหม่'}
            },
            {
                path: '/order/:id',
                component: () => import('@/views/order/OrderDetail'),
                meta: {breadCrumb: 'ออเดอร์'}
            }
        ]
    },
    {
        path: '/oil-tracking',
        component: () => import('@/components/Layout'),
        meta: {breadCrumb: 'ติดตามค่าน้ำมัน', icon: 'mdi-oil'},
        children: [
            // {path: '/', component: () => import('@/views/oilTracker/OilTracker')}
            {
                path: '/',
                component: () => import('@/views/oilTracker/OilTracker'),
                meta: {breadCrumb: 'รายการเติมน้ำมันทั้งหมด'},
            },
            {
                path: '/oil-tracking/create',
                component: () => import('@/views/oilTracker/CreateOilTracker'),
                meta: {permission: 'add_oiltransaction', breadCrumb: 'เพิ่มข้อมูลเติมน้ำมัน'},
            },
            {
                path: '/oil-tracking/vehicle',
                component: () => import('@/components/BlankComponent'),
                meta: {breadCrumb: 'รถ'},
                children: [
                    {
                        path: '/oil-tracking/vehicle',
                        component: () => import('@/views/oilTracker/OilTrackerByCarList'),
                        meta: {breadCrumb: 'เลือกดูตามรถ'},
                    },
                    {
                        path: '/oil-tracking/vehicle/oil',
                        component: () => import('@/components/BlankComponent'),
                        meta: {breadCrumb: 'เติมน้ำมัน'},
                        children: [
                            {
                                path: '/oil-tracking/vehicle/oil/:id',
                                component: () => import('@/views/oilTracker/OilTrackerByOilList'),
                                meta: {breadCrumb: 'เติมน้ำมัน'},
                            }
                        ]
                    },
                    {
                        path: '/oil-tracking/vehicle/:id',
                        component: () => import('@/views/maintenance/MaintenanceByVehicle'),
                        meta: {breadCrumb: 'ข้อมูลการซ่อมบำรุง'}
                    },
                ]
            },
            {
                path: '/oil-tracking/:id',
                component: () => import('@/views/oilTracker/OilTrackerDetailByCar'),
                meta: {breadCrumb: 'ข้อมูลรถ', hasId: true},
            },
        ]
    },
    {
        path: '/stock/',
        component: () => import('@/components/Layout'),
        meta: {breadCrumb: 'คลังสินค้า', icon: 'mdi-widgets'},
        redirect: '/stock/product',
        children: [
            {
                path: '/stock/product',
                component: () => import('@/components/BlankComponent'),
                meta: {breadCrumb: 'สินค้าทั้งหมด'},
                children: [
                    {
                        path: '/stock/product',
                        component: () => import('@/views/stock/Stock'),
                    },
                    {
                        path: '/stock/product/create',
                        component: () => import('@/views/stock/CreateProduct'),
                        meta: {breadCrumb: 'เพิ่มสินค้าใหม่', permission: 'add_product'}
                    },
                    {
                        path: '/stock/product/:id',
                        component: () => import('@/views/stock/ProductDetail'),
                        meta: {breadCrumb: 'ใบสั่งสินค้าหมายเลข', hasId: true}
                    },
                    // {
                    //     path: '/stock/product/:id',
                    //     component: () => import('@/views/stock/ProductDetail'),
                    //     meta: {breadCrumb: 'ข้อมูลสินค้า', hasId: true}
                    // },
                ]
            },
            // {
            //     path: '/stock/product/create',
            //     component: () => import('@/views/stock/CreateProduct'),
            //     meta: {breadCrumb: 'เพิ่มสินค้าใหม่'}
            // },
            {
                path: '/stock/po',
                component: () => import('@/components/BlankComponent'),
                meta: {breadCrumb: 'ใบสั่งสินค้า'},
                children: [
                    {
                        path: '/stock/po',
                        component: () => import('@/views/stock/POList'),
                        // meta: {breadCrumb: 'ใบสั่งสินค้า'},
                    },
                    {
                        path: '/stock/po/create',
                        component: () => import('@/views/stock/CreatePO'),
                        meta: {breadCrumb: 'เพิ่มรายการสั่งสินค้าใหม่'}
                    },
                    {
                        path: '/stock/po/:id',
                        component: () => import('@/views/stock/PODetail'),
                        meta: {breadCrumb: 'ใบสั่งสินค้าหมายเลข', hasId: true}
                    },
                ]
            },
            // {
            //     path: '/stock/po/create',
            //     component: () => import('@/views/stock/CreatePO'),
            //     meta: {breadCrumb: 'เพิ่มรายการสั่งสินค้าใหม่'}
            // },
        ]
    }, {
        path: '/maintenance',
        component: () => import('@/components/Layout'),
        meta: {breadCrumb: 'ประวัติการซ่อมบำรุง', icon: 'mdi-wrench'},
        children: [
            {
                path: '/',
                component: () => import('@/views/maintenance/Maintenance')
            },
            {
                path: '/maintenance/create',
                component: () => import('@/views/maintenance/CreateMaintenance'),
                meta: {breadCrumb: 'เพิ่มรายการการซ่อมบำรุง', permission: 'add_maintenance'}
            },
            {
                path: '/maintenance/:id',
                component: () => import('@/views/maintenance/MaintenanceDetail'),
                meta: {breadCrumb: 'รายการการซ่อมบำรุงเลขที่', hasId: true}
            }
        ]
    }, {
        // path: '/salary',
        // component: () => import('@/components/Layout'),
        // meta: {breadCrumb: 'รายชื่อพนักงาน', icon: 'mdi-cash-usd'},
        // children: [
        //     {
        //         path: '/salary',
        //         component: () => import('@/views/salary/Salary')
        //     },
        //     // {
        //     // path: '/salary/:id',
        //     // component: () => import('@/components/Layout'),
        //     // meta: {breadCrumb: 'ค่าแรง', icon: 'mdi-cash-usd'},
        //     // children: [
        //     {
        //         path: '/salary/commission',
        //         component: () => import('@/components/Layout'),
        //         meta: {breadCrumb: 'ค่าแรง', icon: 'mdi-cash-usd'},
        //         children: [
        //             {
        //                 path: '/salary/commission/:id',
        //                 component: () => import('@/views/salary/SalaryUserList'),
        //                 meta: {breadCrumb: 'ค่าคอมมิชชั่น'}
        //             }
        //         ]
        //     },
        //     {
        //         path: '/salary/:id',
        //         component: () => import('@/views/salary/SalaryDetail'),
        //         meta: {breadCrumb: 'ค่าคอมมิชชั่นค้างจ่าย'}
        //     },
        //
        //     // ]
        //     // },
        //
        // ]
        path: '/salary',
        component: () => import('@/components/Layout'),
        meta: {breadCrumb: 'รายชื่อพนักงาน', icon: 'mdi-cash-usd'},
        children: [
            {
                path: '/salary',
                component: () => import('@/views/salary/Salary'),
                meta: {permission: 'view_commission'}
            },
            {
                path: '/salary/commission',
                component: () => import('@/components/BlankComponent'),
                meta: {breadCrumb: 'ค่าคอมมิชชั่นค้างจ่าย', permission: 'view_commission'},
                children: [
                    {
                        path: '/salary/commission',
                        component: () => import('@/views/salary/SalaryUserList'),
                    },
                    {
                        path: '/salary/commission/:id',
                        component: () => import('@/views/salary/SalaryUserList'),
                        meta: {breadCrumb: 'คอมมิชชั่นทั้งหมด', permission: 'view_commission'}
                    },
                ]
            },
            {
                path: '/salary/history',
                component: () => import('@/components/BlankComponent'),
                meta: {breadCrumb: 'ประวัติการจ่าย'},
                children: [
                    {
                        path: '/salary/history',
                        component: () => import('@/views/salary/salaryHistory'),
                    },
                    {
                        path: '/salary/history/:id',
                        component: () => import('@/views/salary/salaryHistory'),
                        meta: {breadCrumb: 'ประวัติการจ่าย'}
                    },
                ]
            },
            {
                path: '/salary/:id',
                component: () => import('@/views/salary/SalaryDetail'),
                meta: {breadCrumb: 'ค่าคอมมิชชั่นค้างจ่าย', permission: 'view_commission'}
            },
        ]
    },
    {
        path: '/setting',
        component: () => import('@/components/Layout'),
        meta: {breadCrumb: 'ตั้งค่า', icon: 'mdi-nut'},
        children: [
            {
                path: '/setting',
                component: () => import('@/views/setting/Setting')
            },
            {
                path: '/setting/customer',
                component: () => import('@/components/BlankComponent'),
                meta: {breadCrumb: 'รายชื่อลูกค้า', permission: 'view_customer'},
                children: [
                    {
                        path: '/setting/customer',
                        component: () => import('@/views/setting/CustomerList'),
                    },
                    {
                        path: '/setting/customer/create',
                        component: () => import('@/views/setting/CreateCustomer'),
                        meta: {breadCrumb: 'เพิ่มลูกค้าใหม่', permission: 'view_customer'},
                    },
                    {
                        path: '/setting/customer/:id',
                        component: () => import('@/views/setting/CustomerDetail'),
                        meta: {breadCrumb: 'ข้อมูลลูกค้า', permission: 'view_customer'},
                    },
                ]
            }

        ]
    },
    {
        path: '/report',
        component: () => import('@/components/Layout'),
        meta: {breadCrumb: 'รายงาน'},
        children: [
            {
                path: '/report/sale',
                component: () => import('@/views/report/Sale'),
                meta: {breadCrumb: 'รายงานยอดขาย'},
            },
            {
                path: '/report/received',
                component: () => import('@/views/report/Received'),
                meta: {breadCrumb: 'รายงานยอดรับชำระ'},
            },
            {
                path: '/report/overdue',
                component: () => import('@/views/report/OverDue'),
                meta: {breadCrumb: 'รายงานยอดค้างชำระ'},
            },
            {
                path: '/report/product-buy',
                component: () => import('@/views/report/ProductBuy'),
                meta: {breadCrumb: 'รายการซื้อสินค้า'},
            }
        ]
    },
    {
        path: '/login',
        component: () => import('@/views/Login'),
        meta: {icon: null},
    },
    {
        path: '/print',
        component: () => import('@/views/other/PrintPage'),
        meta: {icon: null},
    },
]