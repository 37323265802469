import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import jQuery from  'jquery'
import VueAxios from "vue-axios";
import axios from 'axios';
import vuetify from './plugins/vuetify';
import VueThailandAddressInput from 'vue-thai-address-input';
import Vuelidate from 'vuelidate'
import currency from 'currency.js'

// window.$ = window.jQuery = jQuery;
// import 'popper.js'
// import 'bootstrap'
import VueSession from "vue-session/index.esm";

import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
// import  './assets/app.scss'

Vue.component('splitpanes', Splitpanes)
Vue.component('pane ', Pane)

Vue.use(VueSession);
Vue.use(VueAxios, axios);
Vue.use(VueThailandAddressInput)
Vue.use(Vuelidate)
Vue.component('apexchart', VueApexCharts)
Vue.prototype.$currency = (value) => {
    return new currency(value, {symbol: ''})
}

axios.defaults.baseURL = store.state.endpoints.baseUrL;
// axios.defaults.baseURL = 'https://60b25a4962ab150017ae2062.mockapi.io'
axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.jwt}`
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
