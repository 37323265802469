import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.blue.darken4,
                secondary: colors.amber.darken3,
                success:colors.green.accent4,
                error: colors.red.darken2,
                background: "#F4F4F4",
                sidebar_background:colors.blueGrey.darken2,
                sidebar_active:colors.blueGrey.darken1,
                cancel:colors.blueGrey.lighten4
            }
        },
    },
});
