import Vue from 'vue'
import VueRouter from 'vue-router'
// const routes = require("./route")
import routes from './route'
import store from '../store/index'

Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    const isLoggedIn = store.state.isAuthenticated

    if (!isLoggedIn && to.path !== '/login') {
        // alert('Please login first!')
        next({path: '/login'})
    } else {
        if (to.meta.permission && store.state.authUser.user_permissions.includes(to.meta.permission) === false) {
            console.log('not', to.meta.permission, store.state.authUser.user_permissions.includes(to.meta.permission))
            next(false)
        } else {
            // This goes through the matched routes from last to first, finding the closest route with a title.
            // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
            // `/nested`'s will be chosen.
            const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

            // Find the nearest route element with meta tags.
            const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

            const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

            // If a route with a title was found, set the document (page) title to that value.
            if (nearestWithTitle) {
                document.title = nearestWithTitle.meta.title;
            } else if (previousNearestWithMeta) {
                document.title = previousNearestWithMeta.meta.title;
            }

            // Remove any stale meta tags from the document using the key attribute we set below.
            Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

            // Skip rendering meta tags if there are none.
            if (!nearestWithMeta) return next();

            // Turn the meta tag definitions into actual elements in the head.
            nearestWithMeta.meta.metaTags.map(tagDef => {
                const tag = document.createElement('meta');

                Object.keys(tagDef).forEach(key => {
                    tag.setAttribute(key, tagDef[key]);
                });

                // We use this to track which meta tags we create so we don't interfere with other ones.
                tag.setAttribute('data-vue-router-controlled', '');

                return tag;
            })
                // Add the meta tags to the document head.
                .forEach(tag => document.head.appendChild(tag));

            next();
        }

        // if (store.state.authUser.permissions.includes(authorize)) {
        //     console.log(true)
        // }
        // if (to.meta.guess && isLoggedIn) {
        //     alert("You've already logged in")
        //     next({path: '/'})
        // }
        //
        // next()


    }
});

export default router
