import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        authUser: {},
        isAuthenticated: false,
        jwt: "",
        endpoints: {
            // obtainJWT: "http://localhost:8000/api/auth/obtain/",
            // refreshJWT: "http://localhost:8000/auth/refresh_token/",
            baseUrL: `${window.location.protocol}//${window.location.hostname}`,
        },
        token: null,
        isLoading: false,
        pickInData: [],
        snackbar: {
            text: '',
            visible: false,
            color: 'success'
        },
        cutStock: [],
        drawer: true
    },
    getters: {
        drawer(state) {
            return state.drawer
        },
    },
    mutations: {
        setAuthUser(state, {authUser, isAuthenticated}) {
            // Vue.set(state, "authUser", authUser)
            // Vue.set(state, "isAuthenticated", isAuthenticated)
            state.authUser = authUser
            state.isAuthenticated = isAuthenticated
        },
        updateToken(state, newToken) {
            localStorage.setItem("token", newToken)
            state.jwt = newToken
        },
        removeToken(state) {
            localStorage.removeItem("token")
            state.jwt = null
            state.isAuthenticated = false
            state.authUser = {}
        },
        addPickInData(state, pickIn) {
            let index = state.pickInData.findIndex(el => el.po_item_id === pickIn.po_item_id && el.stock_location_id === pickIn.stock_location_id)
            if (index === -1) {
                state.pickInData.push(pickIn)
            } else {
                state.pickInData[index] = pickIn
            }
        },
        addCutStockData(state, cutStock) {
            let index = state.cutStock.findIndex(el => el.order_item_id === cutStock.order_item_id && el.stock_location_id === cutStock.stock_location_id)
            if (index === -1) {
                state.cutStock.push(cutStock)
            } else {
                state.cutStock[index] = cutStock
            }
        },
        setDrawer(state, data) {
            state.drawer = data
        },
    },
    modules: {},
    plugins: [new VuexPersistence().plugin]
})
