<template>
  <div class="opacity-80" style="opacity: 0.8">

    <div style="width: 100px;" class="center text-center">
      <img width="100px" src="../assets/Pulse-0.9s-144px.svg" alt="Loading...">
      <v-btn icon @click="$store.state.isLoading = false"><v-icon>mdi-close</v-icon></v-btn>
    </div>
  </div>
</template>

<script>
export  default {
  data(){
    return{
    }
  }
}
</script>

<style scoped>
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.opacity-80{
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  background-color: white;
  top: 0;
  left: 0;
}
</style>